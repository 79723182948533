<template>
	<div v-if="verifyEmailView">
		<div v-if="!verifyEmailSuccess">
			<h1 class="mb-10 text-3xl font-semibold text-center">
				{{ t("activate_account") }}
			</h1>
			<div class="form-wrapper">
				<span
					v-show="apiError"
					class="form-wrapper__validation-error mb-3"
				>
					{{ apiError }}
				</span>

				<button
					v-show="!apiError"
					class="form-wrapper__submit uppercase"
					:disabled="apiLoader"
					@click="activateAccount"
				>
					{{ apiLoader ? t("loadingMessage") : t("activate") }}
				</button>
			</div>
		</div>
		<div
			class="p-5 text-center bg-hr-pink"
			v-else
		>
			<h1 class="mb-5 text-2xl text-center">
				{{ t("activate_success") }}
			</h1>
			<a
				:href="webLoginUrl"
				class="form-wrapper__link"
			>
				{{ t("back_to_login") }}
			</a>
		</div>
	</div>
	<div v-else>
		<div
			class="form-wrapper"
			v-if="!generateVerificationKeySuccess"
		>
			<span
				v-show="apiError"
				class="form-wrapper__validation-error mb-3"
			>
				{{ apiError }}
			</span>

			<button
				class="form-wrapper__submit uppercase"
				:disabled="apiLoader"
				@click="generateVerificationKey"
			>
				{{ apiLoader ? t("loadingMessage") : t("generate_verification_key") }}
			</button>
		</div>
		<div
			class="p-5 text-center bg-hr-pink"
			v-else
		>
			<h1 class="mb-5 text-2xl text-center">
				{{ t("generate_success") }}
			</h1>
		</div>
	</div>
</template>

<script>
	import { computed, ref } from "vue";
	import { useRoute } from "vue-router";
	import { useI18n } from "vue-i18n";
	import useApi from "@/composables/useApi";
	import axios from "axios";

	export default {
		name: "VerifyEmail",
		setup() {
			// GLOBAL
			const route = useRoute();
			const { t, te } = useI18n();
			// API
			const { handleApi } = useApi();
			const apiError = ref(null);
			const apiLoader = ref(false);
			// Verification
			const verifyEmailView = ref(true);
			const verifyEmailSuccess = ref(false);

			const generateVerificationKeySuccess = ref(false);
			const webLoginUrl = computed(() => {
				return `${process.env.VUE_APP_WEB_URL}/login`;
			});

			const activateAccount = async () => {
				let payload = {
					key: route.query.key,
				};
				apiLoader.value = true;
				let [response, verifyEmailError] = await handleApi(
					axios.post(`${process.env.VUE_APP_USER_URL}/v1/users/activate`, payload)
				);
				if (verifyEmailError) {
					apiError.value = te(`apiErrors.${verifyEmailError}`)
						? t(`apiErrors.${verifyEmailError}`)
						: t(`apiErrors.ERR_INVALID_BINDING_STRUCTURE`);
					if (
						verifyEmailError === "ERR_NO_DATA_AVAILABLE" ||
						verifyEmailError === "ERR_KEY_INVALID" ||
						verifyEmailError === "ERR_KEY_EXPIRED"
					) {
						verifyEmailView.value = false;
					}
				}
				if (response) verifyEmailSuccess.value = true;

				setTimeout(() => {
					apiLoader.value = false;
				}, 300);
			};

			const generateVerificationKey = async () => {
				let payload = {
					email: route.query.email,
				};
				apiLoader.value = true;
				let [response, generateKeyError] = await handleApi(
					axios.post(`${process.env.VUE_APP_AUTH_URL}/v1/users/request-email-activation`, payload)
				);
				if (generateKeyError)
					apiError.value = te(`apiErrors.${generateKeyError}`)
						? t(`apiErrors.${generateKeyError}`)
						: t(`apiErrors.ERR_INVALID_BINDING_STRUCTURE`);
				if (response) {
					generateVerificationKeySuccess.value = true;
					apiError.value = false;
				}

				setTimeout(() => {
					apiLoader.value = false;
				}, 300);
			};

			return {
				t,
				apiError,
				apiLoader,
				verifyEmailView,
				verifyEmailSuccess,
				generateVerificationKeySuccess,
				activateAccount,
				generateVerificationKey,
				webLoginUrl,
			};
		},
	};
</script>
